import * as React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import backToProjects from '../../../images/back-to-projects.svg'
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import parisVilla1 from "../../../images/paris-villa/paris-villa-1.jpg"
import parisVilla2 from "../../../images/paris-villa/paris-villa-2.jpg"
import parisVilla3 from "../../../images/paris-villa/paris-villa-3.jpg"
import parisVilla4 from "../../../images/paris-villa/paris-villa-4.jpg"
import parisVilla5 from "../../../images/paris-villa/paris-villa-5.jpg"
import parisVilla6 from "../../../images/paris-villa/paris-villa-6.jpg"
import parisVilla7 from "../../../images/paris-villa/paris-villa-7.jpg"
import parisVilla8 from "../../../images/paris-villa/paris-villa-8.jpg"
import parisVilla9 from "../../../images/paris-villa/paris-villa-9.jpg"
import parisVilla10 from "../../../images/paris-villa/paris-villa-10.jpg"
import parisVilla11 from "../../../images/paris-villa/paris-villa-11.jpg"
import parisVilla12 from "../../../images/paris-villa/paris-villa-12.jpg"
import parisVilla13 from "../../../images/paris-villa/paris-villa-13.jpg"
import parisVilla14 from "../../../images/paris-villa/paris-villa-14.jpg"
import parisVilla15 from "../../../images/paris-villa/paris-villa-15.jpg"
import parisVilla16 from "../../../images/paris-villa/paris-villa-16.jpg"
import parisVilla17 from "../../../images/paris-villa/paris-villa-17.jpg"
import parisVilla18 from "../../../images/paris-villa/paris-villa-18.jpg"
import parisVilla19 from "../../../images/paris-villa/paris-villa-19.jpg"
import parisVilla20 from "../../../images/paris-villa/paris-villa-20.jpg"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ParisVilla = () => {
    const { t } = useTranslation();
    const options = {
        buttons: {
            showFullscreenButton: false,
            showDownloadButton: false,
            showThumbnailsButton: false,
            showAutoplayButton: false,
        }
    }
    return (
        <Layout>
            <Seo title="Paris Villa" />
            <div className="mt-40 relative flex items-center justify-center md:mt-28">
                <div className="max-w-full overflow-hidden wrapper">
                    <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARİS {t("Villa")}</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARİS {t("Villa")}</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">PARİS {t("Villa")}</p>
                    </div>
                </div>
                <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto uppercase md:text-3xl md:-mt-1 -mt-10">Paris {t("Villa")}</h1>
            </div>
            <div className="w-full">
                <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                        <div className="w-full flex items-center justify-between flex-wrap md:mt-10">
                            <img src={parisVilla1} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla2} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla3} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla4} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla5} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla6} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla7} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla8} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla9} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla10} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla11} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla12} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla13} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla14} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla15} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla16} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla17} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla18} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla19} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={parisVilla20} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </div>
            <Link to="../" className="font-first text-default text-xl bg-slate-600/10 pr-3 flex items-center w-fit mt-24 ml-16 hover:ml-10 transition-all duration-300 rounded-3xl md:mt-10 md:mx-auto">
                <img src={backToProjects} alt="Projelere Dön" className="mr-3" />
                {t("GeriDon")}
            </Link>
        </Layout>
    )
}

export default ParisVilla;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;